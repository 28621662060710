import React from "react";
import { Route, Link, Routes } from "react-router-dom";
import { Layout, Typography, Space } from "antd";
import {
  Navbar,
 Cryptocurrencies,

} from "../components";
import "../Server.css";


const Cryptocurrencypage = () => {

  return (
    <div className="app">
      <div className="navbar">
        <Navbar />
      </div>
      <div className="main">
        <Layout>
          <div className="routes">
           <Cryptocurrencies/>


          </div>
        </Layout>
      

      <div className="footer" >
   
<Typography.Title level={5} style={{ color:"white", textAlign:"center"}}>

OtcFinance <br/>
All right reserved 

</Typography.Title>


      </div>
    </div>
    </div>
  
  );
};

export default Cryptocurrencypage;
