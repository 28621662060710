import "./App.css";
import { createContext, useState, useEffect } from "react";
import {
  BrowserRouter,
  Routes,
  Route
} from "react-router-dom";
import Home from "./home/Home";

import { ToastProvider } from "react-toast-notifications";
import Server from "./Server";
import {
  Homepage,
  Exchanges,
  CryptoDetails,
  Cryptocurrencies,
  News,
  Cryptocurrencypage
} from "./components";
import Addbalance from "./otcadmin/Addbalance";

  
export const userDataContext = createContext({});


function App() {


  const [type, movieType] = useState("movies");
  const [user, setUser] = useState(false);

  useEffect(() => {
    const userstate = JSON.parse(localStorage.getItem("user"));

    setUser(userstate);
  }, []);



  return (
    <ToastProvider>
      <userDataContext.Provider value={{ type, movieType, user, setUser }}>



        <BrowserRouter>
          <Routes>
            <Route
              path="/"
              element={ <Home />}
            />   
            <Route
              path="/server/*"
              element={ <Server/>}
            />   
              <Route exact path="/homepage" element={<Homepage />} />
              <Route exact path="/exchanges" element={<Exchanges />} />
              <Route
                path="/cryptocurrencies"
                element={<Cryptocurrencies />}
              />
  <Route
                path="/cryptocurrencypage"
                element={<Cryptocurrencypage />}
              />

              <Route
            
                path="/cryptodeatils/:coinid"
                element={<CryptoDetails />}
              />
              <Route path="/news" element={<News />} />
              <Route
            
            path="/addbalance"
            element={<Addbalance />}
          />
            </Routes>
     
        </BrowserRouter>
      </userDataContext.Provider>
    </ToastProvider>
  );
}

export default App;
