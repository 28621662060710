import React from "react";

import millify from "millify";
import { Typography, Row, Col, Statistic } from "antd";
import { useGetCryptosQuery } from "../services/cryptoApi";
import { Link } from "react-router-dom";
import Cryptocurrencies from "./Cryptocurrencies";
import  Accountdetails from "./Accountdetails" 
import News from "./News";
const { Title } = Typography;

const Homepage = () => {
  const { data, isFetching } = useGetCryptosQuery(10);

  const globalStats = data?.data?.stats;

  if (isFetching) return "loading..";


  return (
    <>
      <div className="home-heading-container">
        <Title level={2} className="home-title">
          Account balance
        </Title>
 
      </div>


<Accountdetails/>
      <Title level={2} className="heading">
        Global Crypto Stats
      </Title>
      <Row>
        <Col span={12}>
          <Statistic
            title=" Total Cryptocurrencies"
            value={globalStats.total}
          />
        </Col>
        <Col span={12}>
          <Statistic
            title=" Total Exchanges"
            value={millify(globalStats.totalExchanges)}
          />
        </Col>

        <Col span={12}>
          <Statistic
            title=" Total Market Cap"
            value={millify(globalStats.totalMarketCap)}
          />
        </Col>
        <Col span={12}>
          <Statistic
            title=" Total 24H Volume"
            value={millify(globalStats.total24hVolume)}
          />
        </Col>
        <Col span={12}>
          <Statistic
            title=" Total Markets"
            value={millify(globalStats.totalMarkets)}
          />
        </Col>
      </Row>

      <div className="home-heading-container">
        <Title level={2} className="home-title">
          Top ten cryptocurrencies in the world
        </Title>
        <Title level={3} className="home-title">
          <Link to="/cryptocurrencypage"> Show more </Link>
        </Title>
      </div>
      <Cryptocurrencies simplified />

     
    </>
  );
};

export default Homepage;
