import React from "react";
import { Row, Col, Typography, Form, Input, Select, Table, Button } from "antd";

import { useNavigate } from "react-router-dom";
import { useToasts } from "react-toast-notifications";
import axios from "axios";

const Addbalance = () => {
  const { addToast } = useToasts();
  let navigate = useNavigate();

  const [enteredAmount, setenteredAmount] = React.useState("");
  const [enteredCurency, setenteredCurrency] = React.useState("fiat");
  const [user, setUser] = React.useState("");
  const [balances, setBalalnce] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(false);

  React.useEffect(() => {
    const headers = {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json",
    };

    axios
      .get(
        "https://www.otcfinanceuk.com/otcnew/otcnew/api/balance/allbalance",
        {
          headers: headers,
        }
      )
      .then((res) => {
        if (res.status === 200) {
          return setBalalnce(res.data.data), console.log(res.data.data);
        }

        // window.location = "/retrieve"; //This line of code will redirect you once the submission is succeed
      })
      .catch((error) => {
        if (error.response.status === 400) {
          return addToast("You cannot add more than one balance type", {
            appearance: "error",
          });
        } else if (error.response.status === 500) {
          return addToast("Error adding balance", {
            appearance: "warning",
          });
        }
      });
  }, []);

  const amountChangehandler = (event) => {
    setenteredAmount(event.target.value);
  };

  const curencyChangehandler = (event) => {
    setenteredCurrency(event.target.value);
  };

  const submitHandler = (event) => {
    event.preventDefault();

    setIsLoading(true);
    const balance = {
      amount: enteredAmount,
      balancetype: enteredCurency,
    };

    const headers = {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json",
    };

    axios
      .post(
        "https://www.otcfinanceuk.com/otcnew/otcnew/api/balance/addbalance",
        balance,
        {
          headers: headers,
        }
      )
      .then((res) => {
        if (res.status === 200) {
          return (
            setIsLoading(false),
            setUser(res.data),
            addToast("New balance added successfully", {
              appearance: "success",
            }),
            setIsLoading(false)
          );
        }

        setIsLoading(false);
        // window.location = "/retrieve"; //This line of code will redirect you once the submission is succeed
      })
      .catch((error) => {
        if (error.response.status === 400) {
          setIsLoading(false);
          return addToast("You cannot add more than one balance type", {
            appearance: "error",
          });
        } else if (error.response.status === 500) {
          setIsLoading(false);
          return addToast("Error adding balance", {
            appearance: "warning",
          });
        }
      });
  };

  const handleDelete = (id) => {
    //  console.log(id)

    const headers = {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json",
    };

    axios
      .delete(
        `https://www.otcfinanceuk.com/otcnew/otcnew/api/balance/deletebalance/${id}`,
        {
          headers: headers,
        }
      )
      .then((res) => {
        if (res.status === 200) {
          return (
            setUser("data"),
            addToast("Deleted Succesfully", {
              appearance: "success",
            })
          );
        }

        // window.location = "/retrieve"; //This line of code will redirect you once the submission is succeed
      })
      .catch((error) => {
        if (error.response.status === 404) {
          return addToast("Balance not Avialble", {
            appearance: "error",
          });
        } else {
          return addToast("Balance not Avialble", {
            appearance: "warning",
          });
        }
      });
  };
  return (
    <div style={{ textAlign: "center", padding: 20 }}>
      <Typography.Title level={2} className="heading">
        Add balance
      </Typography.Title>

      <Row gutter={[8, 8]} justify="center">
        <Col xs={24} sm={12} lg={12}>
          <form onSubmit={submitHandler}>
            <Input
              className="forminput"
              type="number"
              placeholder="Enter amount"
              onChange={amountChangehandler}
              required
            />
            <br />
            <br />
            <select
              onChange={curencyChangehandler}
              placeholder="Select a currency type"
              value={enteredCurency}
            >
              <option value="fiat">Fiat</option>
              <option value="crypto">Cryptocurrency</option>
            </select>
            <br />
            <br />
            {isLoading ? (
              <Input type="button" className="submitinput" value="Loading" />
            ) : (
              <Input type="submit" className="submitinput" value="SUBMIT" />
            )}
          </form>
        </Col>
      </Row>
      <Row justify="center">
        <Col xs={24} sm={24} lg={24}>
          <table
            style={{ width: "100%", border: "1px solid #ddd", padding: "8px" }}
          >
            <thead style={{ padding: 30 }}>
              <tr style={{ padding: "30px" }}>
                <th style={{ padding: "30px", border: "1px solid #ddd" }}>
                  Cryto Type
                </th>

                <th style={{ padding: "30px", border: "1px solid #ddd" }}>
                  Amount
                </th>

                <th></th>
              </tr>
            </thead>

            {balances?.map((balance, index) => {
              return (
                <tbody>
                  <tr>
                    <td style={{ padding: "30px", border: "1px solid #ddd" }}>
                      {" "}
                      <p key={`${balance._id}`}>{balance.balancetype}</p>
                    </td>

                    <td style={{ padding: "30px", border: "1px solid #ddd" }}>
                      {" "}
                      <p key={`${balance._id}`}>{balance.amount}</p>
                    </td>
                    <td>
                      {" "}
                      <Button
                        key={`${balance._id}`}
                        type="danger"
                        onClick={() => handleDelete(balance._id)}
                      >
                        Delete
                      </Button>
                    </td>
                  </tr>
                </tbody>
              );
            })}
          </table>
        </Col>
      </Row>
    </div>
  );
};

export default Addbalance;
