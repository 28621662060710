import React, { useState, useContext } from "react";
import { useToasts } from "react-toast-notifications";
import { useNavigate } from "react-router-dom";
import { Form } from "react-bootstrap";
import "./Login.css";
import { Input } from "antd";

import { userDataContext } from "../App";

const Loginform = () => {
  const navigate = useNavigate();

  const { addToast } = useToasts();

  const [enteredEmail, setenteredEmail] = useState("");
  const [enteredPassword, setenteredPassword] = useState("");

  const { setUser } = useContext(userDataContext);

  const emailChangehandler = (event) => {
    setenteredEmail(event.target.value);
  };

  const passwordChangehandler = (event) => {
    setenteredPassword(event.target.value);
  };

  const submitHandler = (event) => {
    event.preventDefault();

    const user = {
      email: enteredEmail,
      password: enteredPassword,
    };

    if (
      user.email === "federico0323@yahoo.com" &&
      user.password === "1234520222"
    ) {
      setUser(true);

      setTimeout(() => {
        navigate("/server");
      }, 9100);
    } else {
      return addToast("inavlid  Email or Passowrd", {
        appearance: "error",
      });
    }
  };

  return (
    <div>
      {" "}
      <Form onSubmit={submitHandler}>
        <Input
          className="forminput"
          type="email"
          placeholder="Enter email"
          onChange={emailChangehandler}
          required
        />

        <Input
          className="forminput"
          type="password"
          placeholder="Enter password"
          onChange={passwordChangehandler}
          required
        />

        <Input type="submit" className="submitinput" value="SUBMIT" />
      </Form>
    </div>
  );
};

export default Loginform;
