import React, { useEffect, useState } from "react";
import { Button, Menu, Typography, Avatar } from "antd";
import { Link } from "react-router-dom";
import Icon from "../assets/cryptocurrency.png";

import {
  HomeOutlined,
  MoneyCollectOutlined,
  BulbOutlined,
  FundOutlined,
  MenuOutlined,
} from "@ant-design/icons";

const Navbar = () => {

  const [ activeMenu, setActiveMenu] = useState(true);
  const [ screenSize,setScreenSize] = useState(null);

  
  useEffect(()=>{
  const handleResize = () => setScreenSize(window.innerWidth);
  window.addEventListener('resize', handleResize);

  handleResize();


  return () => window.removeEventListener('resize',handleResize);
    


  },[])


  useEffect(()=>{

  
if(screenSize < 768){

  setActiveMenu(false)
}else{

  setActiveMenu(true)
}



  },[screenSize])
  return (
    <div className="nav-container">
      <div className="logo-container">
        <Avatar src={Icon} size="large" />
        <Typography.Title level={2} className="logo">
          <Link to="">OtcFinance</Link>
        </Typography.Title>
        {/* <Button className="menu-control-container">


        </Button> */}


{ !activeMenu && (
      
      <Button className="menu-control-controller"  onClick={() => setActiveMenu(true)}><MenuOutlined/></Button>

)}
      </div>

   
      { activeMenu && (



<Menu theme="dark">
<Menu.Item icon={<HomeOutlined />}>
  <Link to="/server">Home</Link>
</Menu.Item>
<Menu.Item icon={<FundOutlined />}>
  <Link to="/cryptocurrencypage"> Cryptocurrencies </Link>
</Menu.Item>

<Menu.Item icon={<BulbOutlined />}>
  <Link to="/">Logout</Link>
</Menu.Item>
</Menu>

      )}

    </div>
  );
};

export default Navbar;
