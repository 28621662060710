import React from "react";
import { Card, Row, Col, Button, Modal, Input } from "antd";
import axios from "axios";
import { useState } from "react";
import { useToasts } from "react-toast-notifications";

const Accountdetails = () => {
  const { addToast } = useToasts();
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const [isModalOpen1, setIsModalOpen1] = React.useState(false);
  const [databalance, setData] = React.useState([]);
  const [decisionModal, setisDecisionModal] = React.useState(false);

  React.useEffect(() => {
    const headers = {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json",
    };

    axios
      .get("https://www.otcfinanceuk.com/otcnew/otcnew/api/balance/allbalance", {
        headers: headers,
      })
      .then((res) => {
        if (res.status === 200) {
          console.log(res.data.data)
          return setData(res.data.data);
        }

        // window.location = "/retrieve"; //This line of code will redirect you once the submission is succeed
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const showDecision = () => {
    setisDecisionModal(true);
  };

  const showModal1 = () => {
    setIsModalOpen1(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
    setIsModalOpen1(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    setIsModalOpen1(false);
    setisDecisionModal(false);
  };

  const [amount, setAmount] = useState("");
  const [bankname, setBankname] = useState("");
  const [accountname, setAccountname] = useState("");
  const [accountnumber, setAcccountnumber] = useState("");
  const [routing, setRouting] = useState("");
  const [swiftcode, setSwiftcode] = useState("");
  const [transact, setTransact] = useState("");

  const [transaction, setTransaction] = useState([]);

  React.useEffect(() => {
    const headers = {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json",
    };

    axios
      .get("https://www.otcfinanceuk.com/otcnew/otcnew/api/transact/alltransaction", {
        headers: headers,
      })
      .then((res) => {
        if (res.status === 200) {
          return setTransaction(res.data.data), console.log(transaction);
        }

        // window.location = "/retrieve"; //This line of code will redirect you once the submission is succeed
      })
      .catch((error) => {
        if (error.response.status === 400) {
          return addToast("You cannot add more than one balance type", {
            appearance: "error",
          });
        } else if (error.response.status === 500) {
          return addToast("Error adding balance", {
            appearance: "warning",
          });
        }
      });
  }, [transact]);

  const submitHandler = (event) => {
    event.preventDefault();

    const transact = {
      amount: amount,
      bankname: bankname,
      accountname: accountname,
      accountnumber: accountnumber,
      routing: routing,
      swiftcode: swiftcode,
      transactiontype: "fiat",
    };

    console.log(transact);

    const headers = {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json",
    };

    axios
      .post(
        "https://www.otcfinanceuk.com/otcnew/otcnew/api/transact/addtransact",
        transact,
        {
          headers: headers,
        }
      )
      .then((res) => {
        if (res.status === 200) {
          return (
            setTransact(res.data),
            handleCancel(),
            addToast(" Transfer made successfully", {
              appearance: "success",
            })
          );
        }

        // window.location = "/retrieve"; //This line of code will redirect you once the submission is succeed
      })
      .catch((error) => {
        if (error.response.status === 500) {
          return addToast("Error sending funds", {
            appearance: "error",
          });
        } else if (error.response.status === 402) {
          return addToast("Insuficient Funds", {
            appearance: "error",
          });
        } else {
          return addToast("Insuficient FundsError sending funds", {
            appearance: "error",
          });
        }
      });
  };

  const submitcryptoHandler = (event) => {
    event.preventDefault();

    const transact = {
      amount: amount,
      accountname: accountname,
      transactiontype: "fiat",
    };

    console.log(transact);

    const headers = {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json",
    };

    axios
      .post(
        "https://www.otcfinanceuk.com/otcnew/otcnew/api/transact/addtransact",
        transact,
        {
          headers: headers,
        }
      )
      .then((res) => {
        if (res.status === 200) {
          return (
            setTransact(res.data),
            handleCancel(),
            addToast(" Transfer made successfully", {
              appearance: "success",
            })
          );
        }

        // window.location = "/retrieve"; //This line of code will redirect you once the submission is succeed
      })
      .catch((error) => {
        if (error.response.status === 500) {
          return addToast("Error sending funds", {
            appearance: "error",
          });
        } else if (error.response.status === 402) {
          return addToast("Insuficient Funds", {
            appearance: "error",
          });
        } else {
          return addToast("Insuficient FundsError sending funds", {
            appearance: "error",
          });
        }
      });
  };

  return (
    <>
      <Row
        gutter={[12, 12]}
        className="crypto-card-container"
        style={{ height: "50%" }}
      >
        <Col xs={24} sm={12} lg={12} className="crypto-card">
          <Card title="Available Balance" hoverable>
            <p>
              {" "}
              Aviailable Balance:
              {databalance[0]?.balancetype === "fiat"
                ? databalance[0]?.amount
                : databalance[0]?.balancetype === "crypto"
                ? databalance[1]?.amount
                : null}
              BTC
            </p>

            <Row gutter={[5, 5]}>
              <Col className="crypto-card" xs={24} sm={12} lg={12}>
                <Button type="primary" onClick={showDecision}>
                  Send
                </Button>
                <Modal
                  title="Sending Options"
                  open={decisionModal}
                  onOk={handleOk}
                  onCancel={handleCancel}
                >
                  <Row gutter={[8, 8]} justify="center">
                    <Col xs={24} sm={12} lg={12}>
                      <Button  onClick={showModal}>Send as Fiat</Button>
                    </Col>
                    <Col xs={24} sm={12} lg={12}>
                      <Button onClick={showModal1}>Send as Crypto</Button>
                    </Col>
                  </Row>
                </Modal>


                <Modal
                  title="Fiat Balance"
                  open={isModalOpen}
                  onOk={handleOk}
                  onCancel={handleCancel}
                >
                  <Row gutter={[8, 8]} justify="center">
                    <Col xs={24} sm={12} lg={24}>
                      <form onSubmit={submitHandler}>
                        <Input
                          className="forminput"
                          type="number"
                          placeholder="Enter amount"
                          onChange={(e) => {
                            setAmount(e.target.value);
                          }}
                          required
                        />
                        <br />
                        <br />

                        <Input
                          className="forminput"
                          type="text"
                          placeholder="Enter Bankname"
                          onChange={(e) => {
                            setBankname(e.target.value);
                          }}
                          required
                        />
                        <br />
                        <br />

                        <Input
                          className="forminput"
                          type="text"
                          placeholder="Enter Accountname"
                          onChange={(e) => {
                            setAccountname(e.target.value);
                          }}
                          required
                        />
                        <br />
                        <br />
                        <Input
                          className="forminput"
                          type="text"
                          placeholder="Enter Account Number"
                          onChange={(e) => {
                            setAcccountnumber(e.target.value);
                          }}
                          required
                        />
                        <br />
                        <br />
                        <Input
                          className="forminput"
                          type="text"
                          placeholder="Enter Routing"
                          onChange={(e) => {
                            setRouting(e.target.value);
                          }}
                          required
                        />
                        <br />
                        <br />
                        <Input
                          className="forminput"
                          type="text"
                          placeholder="Enter Swiftcode"
                          onChange={(e) => {
                            setSwiftcode(e.target.value);
                          }}
                          required
                        />
                        <br />
                        <br />
                        <Input
                          type="submit"
                          className="submitinput"
                          value="SUBMIT"
                          style={{ backgound: "#1890ff" }}
                        />
                      </form>
                    </Col>
                  </Row>
                </Modal>
             
              </Col>
            </Row>
          </Card>
        </Col>
        <Col xs={24} sm={12} lg={12} className="crypto-card">
          <Card title="Deposit Balance" hoverable>
            <p>
              {" "}
        
              Deposit Balance:
              {databalance[0]?.balancetype === "crypto"
                ? databalance[0]?.amount
                : databalance[0]?.balancetype === "fiat"
                ? databalance[1]?.amount
                : null}
              BTC
            </p>

            <Row gutter={[5, 5]}>
              <Col className="crypto-card" xs={24} sm={12} lg={12}>
         
                <Modal
                  title="Withdrawl Crypto"
                  open={isModalOpen1}
                  onOk={handleOk}
                  onCancel={handleCancel}
                >
                  <Row gutter={[8, 8]} justify="center">
                    <Col xs={24} sm={12} lg={24}>
                      <form onSubmit={submitcryptoHandler}>
                        <Input
                          className="forminput"
                          type="number"
                          placeholder="Enter amount"
                          onChange={(e) => {
                            setAmount(e.target.value);
                          }}
                          required
                        />
                        <br />
                        <br />

                        <Input
                          className="forminput"
                          type="text"
                          placeholder="Enter Crypto address"
                          onChange={(e) => {
                            setAccountname(e.target.value);
                          }}
                          required
                        />
                        <br />
                        <br />

                        <Input
                          type="submit"
                          className="submitinput"
                          value="SUBMIT"
                          style={{ backgound: "#1890ff" }}
                        />
                      </form>
                    </Col>
                  </Row>
                </Modal>
                <Button type="dashed">Receive</Button> <br />
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>

      <Row justify="center">
        <Col xs={24} sm={24} lg={24}>
          <table
            style={{ width: "100%", border: "1px solid #ddd", padding: "20px" }}
          >
            <thead
              style={{ padding: 30, background: "#1890ff", color: "white" }}
            >
              <tr style={{ padding: "30px" }}>
                <th style={{ padding: "10px", border: "1px solid #ddd" }}>
                  Account name
                </th>

                <th style={{ padding: "10px", border: "1px solid #ddd" }}>
                  Transaction Type
                </th>

                <th style={{ padding: "10px", border: "1px solid #ddd" }}>
                  Amount
                </th>
              </tr>
            </thead>

            {transaction?.map((transactioning, index) => {
              return (
                <tbody>
                  <tr>
                    <td style={{ padding: "10px", border: "1px solid #ddd" }}>
                      {" "}
                      <p key={`${transactioning._id}`}>
                        {transactioning.accountname.toUpperCase()}
                      </p>
                    </td>

                    <td style={{ padding: "10px", border: "1px solid #ddd" }}>
                      {" "}
                      <p key={`${transactioning._id}`}>
                        {transactioning.transactiontype.toUpperCase()}
                      </p>
                    </td>

                    <td style={{ padding: "10px", border: "1px solid #ddd" }}>
                      {" "}
                      <p key={`${transactioning._id}`}>
                        {transactioning.amount}
                      </p>
                    </td>
                    <td> </td>
                  </tr>
                </tbody>
              );
            })}
          </table>
        </Col>
      </Row>
    </>
  );
};

export default Accountdetails;
